.menu-toggle {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 4px;

  width: 24px;
  height: 20px;

  transition: all 0.6s;
}

@media (max-width: 640px) {
  .menu-toggle {
    display: flex;
  }
}

.menu-toggle span {
  width: 18px;
  height: 2px;
  border-radius: 2px;
  background-color: #323232 !important;

  transition: all 0.6s;
}

.menu-toggle[aria-pressed='true'] {
  z-index: 10;
}

.menu-toggle[aria-pressed='true'] span {
  width: 20px;
}

.menu-toggle[aria-pressed='true'] span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 4px);
}

.menu-toggle[aria-pressed='true'] span:nth-child(2) {
  width: 0;
}

.menu-toggle[aria-pressed='true'] span:nth-child(3) {
  transform: rotate(-45deg) translate(4px, -4px);
}
